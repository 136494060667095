import React, { FunctionComponent } from "react"
import { Helmet as Head } from "react-helmet"
import LoginPrompt from "../components/login-prompt"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3"
import { lightTheme, darkTheme } from "../services/theme"
import { useStyle } from "../hooks/use-style"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"

const LoginPage: FunctionComponent = () => {
  const css = useStyle(theme => ({
    borderColor: theme === "light" ? lightTheme.color.border : "transparent",
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))
  const { search } = useLocation()

  const fromExtension = search == "?extension=1"
  if (!fromExtension) {
    navigate("/")
  }

  return (
    <>
      {fromExtension && (
        <ModalRoutingContext.Consumer>
          {({ modal, closeTo }) => (
            <div className="dialog">
              <Head>
                <title>Smartlike</title>
                <meta name="description" content="Smartlike p2p donations" />
                <meta property="og:title" content="Smartlike" />
                <meta
                  property="og:description"
                  content="Smartlike p2p donations"
                />
              </Head>
              <LoginPrompt
                closeTo={() => {}}
                initialCreate={false}
                pendingCall={null}
              />
              <style jsx>{`
                .dialog {
                  font-weight: normal;
                  font-family: system-ui;
                  max-width: 600px;
                  width: 100%;
                  z-index: 20000;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  padding: 20px;
                  border: 1px solid ${css.borderColor};
                  border-radius: 5px;

                  background-color: #fff;
                  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                    0 6px 20px 0 rgba(0, 0, 0, 0.19);
                }
              `}</style>
            </div>
          )}
        </ModalRoutingContext.Consumer>
      )}
    </>
  )
}
export default LoginPage
